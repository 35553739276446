<template>
  <div class="pb-2">
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableTrashColumns"
      :create-page-url="{ name: 'user-create' }"
      back-to-list-path="user-list"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/getInActiveUsersList`,
      }"
    >
      <template #cell(name)="{ data }">
        {{ data.item.firstname + ' ' + data.item.lastname }}
      </template>
      <template #cell(is_active)="{ data }">
        {{ $t(data.value ? 'Active': 'Inactive') }}
      </template>

      <template #cell(actions)="{ data }">
        <div
          class="text-nowrap d-flex"
          style="gap: 8px"
        >
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom="$t('Delete')"
            icon="LTrashIcon"
            size="16"
            class="featherIcon cursor-pointer border-dotted"
            @click="remove(data.item)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover
            :title="$t('Set to active')"
            icon="LLoadIcon"
            size="24"
            class="featherIcon cursor-pointer border-dotted"
            @click="restoreContact(data.item)"
          />
        </div>
      </template>
      <template #filter>
        {{ '' }}
      </template>
    </l-table>
  </div>
</template>

<script>

import LTable from '@/views/components/LTable/LTable.vue'
import userMenuItems from '@/views/components/sub-menu/settings-sub-menu/users'
import { title } from '@core/utils/filter'
import { VBTooltip } from 'bootstrap-vue'
import tableConfig from '../userConfig'

export default {
  name: 'UserTrashList',
  components: {
    LTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  created() {
    this.$emit('updateMenu', 'users')
  },
  methods: {
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    remove(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/del`).then(() => {
        this.refetchData()
      })
    },
    update(data) {
      this.$router.push({ name: 'settings-users-role-update', params: { id: data.id } })
    },
    restoreContact(user) {
      this.confirmNotification(this, user, `${this.MODULE_NAME}/setActive`, {
        title: 'Are you sure you want to reactivate this ?',
        text: '',
        confirmButtonText: 'Reactivate',
      })
        .then(() => {
          this.refetchData()
        })
    },
  },
  setup() {
    const MODULE_NAME = 'users'
    const { menuItems } = userMenuItems('users')
    const { tableTrashColumns, ACCESS_ABILITY_FOR_SYSTEM_USERS } = tableConfig()
    return {
      tableTrashColumns,
      MODULE_NAME,
      menuItems,
      title,
      ACCESS_ABILITY_FOR_SYSTEM_USERS,
    }
  },
}
</script>
