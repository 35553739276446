var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableTrashColumns,"create-page-url":{ name: 'user-create' },"back-to-list-path":"user-list","table-config-options":{
      endpoint: (_vm.MODULE_NAME + "/getInActiveUsersList"),
    }},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.firstname + ' ' + data.item.lastname)+" ")]}},{key:"cell(is_active)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.$t(data.value ? 'Active': 'Inactive'))+" ")]}},{key:"cell(actions)",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticClass:"text-nowrap d-flex",staticStyle:{"gap":"8px"}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",value:(_vm.$t('Delete')),expression:"$t('Delete')",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"featherIcon cursor-pointer border-dotted",attrs:{"icon":"LTrashIcon","size":"16"},on:{"click":function($event){return _vm.remove(data.item)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"featherIcon cursor-pointer border-dotted",attrs:{"title":_vm.$t('Set to active'),"icon":"LLoadIcon","size":"24"},on:{"click":function($event){return _vm.restoreContact(data.item)}}})],1)]}},{key:"filter",fn:function(){return [_vm._v(" "+_vm._s('')+" ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }